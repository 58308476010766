import styles from './style.module.css'
import Image from 'next/image'

// Import grid images

import imgFendi1 from '../../public/contentgrid/fendi1.jpg'
import imgFendi2 from '../../public/contentgrid/fendi2.jpg'
import imgFendi3 from '../../public/contentgrid/fendi3.jpg'
import imgFendi4 from '../../public/contentgrid/fendi4.jpg'
import imgFutur1 from '../../public/contentgrid/thefutur1.jpg'
import imgFigma1 from '../../public/contentgrid/figma1.jpg'
import imgClump1 from '../../public/contentgrid/clump1.jpg'
import imgClump2 from '../../public/contentgrid/clump2.jpg'
import imgHourglass1 from '../../public/contentgrid/hourglass1.jpg'
import imgKp1 from '../../public/contentgrid/kp1.jpg'
import imgPills1 from '../../public/contentgrid/pills1.jpg'
import imgRocks1 from '../../public/contentgrid/rocks1.jpg'
import imgSl1 from '../../public/contentgrid/sl1.jpg'
import imgSl2 from '../../public/contentgrid/sl2.jpg'
import imgSl3 from '../../public/contentgrid/sl3.jpg'
import imgSl4 from '../../public/contentgrid/sl4.jpg'
import imgSphere1 from '../../public/contentgrid/sphere1.jpg'
import imgSwitch1 from '../../public/contentgrid/switch1.jpg'
import imgCloth1 from '../../public/contentgrid/cloth1.jpg'
import imgAbstract1 from '../../public/contentgrid/abstract1.jpg'
import imgCloudApp2 from '../../public/contentgrid/cloud-app2.jpg'
import imgFuturText1 from '../../public/contentgrid/thefuturtext1.jpg'
import imgShiba1 from '../../public/contentgrid/shiba1.jpg'
import imgAnubi1 from '../../public/contentgrid/anubi1.jpg'
import imgHourGlass2 from '../../public/contentgrid/hourglass2.jpg'
import imgPancake1 from '../../public/contentgrid/pancake1.jpg'
import imgTreasure1 from '../../public/contentgrid/treasure1.jpg'
import imgCoso1 from '../../public/contentgrid/coso1.jpg'
import imgHolidays1 from '../../public/contentgrid/holidays1.jpg'
import imgNikeSkate1 from '../../public/contentgrid/nikeskate.jpg'
import imgKnit1 from '../../public/contentgrid/knit.jpg'
import imgHwBag1 from '../../public/contentgrid/hwbag.jpg'
import imgHublot1 from '../../public/contentgrid/hublot.jpg'
import imgBoem1 from '../../public/contentgrid/boem.jpg'
import imgConverseCherry1 from '../../public/contentgrid/conversecherry1.jpg'
import imgConverseCherry2 from '../../public/contentgrid/conversecherry2.jpg'

export default function ContentGrid ({
  small = false
}) {
  const boxedImage = (src, key = '0', alt = 'Content Image') => (
    <div className={styles.imageBox} key={key}>
      <Image
        className={styles.image}
        src={src}
        alt={alt}
        fill
        data-speed='auto'
        placeholder='blur'
        sizes='20vw'
        priority
      />
    </div>
  )

  const columnImages = [
    // Column 1
    [
      imgPills1,
      imgFendi3,
      imgClump1,
      imgKp1,
      imgRocks1,
      imgShiba1
    ],
    // Column 2
    [
      imgFendi2,
      imgKnit1,
      imgFutur1,
      imgBoem1,
      imgCloudApp2,
      imgAnubi1
    ],
    // Column 3
    [
      imgSl2,
      imgConverseCherry1,
      imgFendi1,
      imgHolidays1,
      imgHourGlass2
    ],
    // Column 4
    [
      imgSwitch1,
      imgSphere1,
      imgNikeSkate1,
      imgHublot1,
      imgFendi4,
      imgPancake1
    ],
    // Column 5
    [
      imgClump2,
      imgHwBag1,
      imgSl3,
      imgCloth1,
      imgFigma1,
      imgAbstract1,
      imgTreasure1
    ],
    // Column 6
    [
      imgSl4,
      imgHourglass1,
      imgConverseCherry2,
      imgFuturText1,
      imgSl1,
      imgCoso1
    ]
  ]

  return (
    <div
      className={styles.container} style={{
        maxHeight: small ? '80vw' : '125vw'
      }}
    >
      <div className={styles.imageContainer}>
        <div
          className={styles.imageColumn} data-speed={1.2}
          style={{
            marginTop: '-12%'
          }}
        >
          {
            columnImages[0].map((src, i) => (
              boxedImage(src, i)
            ))
          }
        </div>
        <div
          className={styles.imageColumn} data-speed={1.1}
          style={{
            marginTop: '-2%'
          }}
        >
          {
            columnImages[1].map((src, i) => (
              boxedImage(src, i)
            ))
          }
        </div>
        <div
          className={styles.imageColumn} data-speed={0.8}
          style={{
            marginTop: '5%'
          }}
        >
          {
            columnImages[2].map((src, i) => (
              boxedImage(src, i)
            ))
          }
        </div>
        <div
          className={styles.imageColumn} data-speed={0.9}
          style={{
            marginTop: '-4%'
          }}
        >
          {
            columnImages[3].map((src, i) => (
              boxedImage(src, i)
            ))
          }
        </div>
        <div
          className={styles.imageColumn} data-speed={1.1}
          style={{
            marginTop: '5%'
          }}
        >
          {
            columnImages[4].map((src, i) => (
              boxedImage(src, i)
            ))
          }
        </div>
        <div
          className={styles.imageColumn} data-speed={0.7}
          style={{
            marginTop: '-2%'
          }}
        >
          {
            columnImages[5].map((src, i) => (
              boxedImage(src, i)
            ))
          }
        </div>
      </div>
    </div>
  )
}
