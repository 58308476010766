import styles from './style.module.css'

export default function WaveClip ({
  color = '#FCFCFC'
}) {
  const waveClip = key => (
    <div
      key={key}
      className={styles.waveClip} style={{
        backgroundColor: color
      }}
    />
  )
  return (
    <div className={styles.container}>
      {Array(12).fill().map((_, i) => waveClip(i))}
      <div
        key='adfjda'
        className={styles.waveClip} style={{
          backgroundColor: color
        }}
      />
    </div>
  )
}
